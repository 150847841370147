.dashboard-container {
    height: fit-content;

    width: 100%;
    background-image: none !important;
}

.dash-label {
    max-width: 1535px;
    margin: auto;
    padding: 0px 60px;

}

.dash-label>h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 12px;

    margin-top: 50px;

}

/*-----------------------------Side Toolbar CSS-----------------------------*/

.vert-divider {
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
}

.vert-line {
    border-right: solid 1.5px rgba(255, 255, 255, 0.2);
    height: 100%;

}


.side-home-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.2s ease;
    margin-top: 15px;
    padding-left: 0px;
    position: fixed;
    top: -7px;
    font-weight: 600;
}

.side-home-btn:hover {
    opacity: 1;
}


.side-dock-btn {

    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;

    border: none;

    gap: 8px;
    padding-left: 13px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    position: sticky;
    top: 150px;
}

.side-dock>a {
    position: sticky;
    top: 260px;
    width: 180px;
}

.side-dock-btn>i {
    color: var(--main-color);
}

.side-dock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: fit-content;
    height: fit-content;
    position: sticky;
    top: 0px
}

.side-logo {
    display: flex;
    justify-content: center;
    padding: 35px;
}

.side-logo>img {
    width: 120px;
}

.side-button>input {
    display: none;
}


.side-button>label>span {
    font-weight: normal;
    ;
}

.side-buttons {

    width: 215px;
    margin: 18px;
    height: fit-content;
    min-width: 180px;
    position: sticky;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: sticky;
    top: 18px;


}

.overSide {
    position: sticky;
    top: 370px;
    z-index: 1000;
}

.right-offset {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: -8px;
    margin-right: 35px;
}

.right-offset>.smart-strip {
    margin-right: 25px;
}

.side-toolbar-content {
    height: fit-content;
    display: flex;
    justify-content: center;
}



.side-button>label {
    font-weight: 600;
    font-size: var(--px15);

    display: flex;
    width: 100%;
    cursor: pointer;
    transition: 0.1s ease;

    outline: solid 1px transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    gap: 10px;
}

.side-button>label:hover {

    opacity: 0.7;
    cursor: pointer;

}

.side-button>input:checked~label {
    background-color: var(--frostedBackground);
    outline: var(--frostedBorder);
    box-shadow: var(--lightShadow);
    border-radius: 8px;
}

.side-button>input:checked~label:hover {
    opacity: 1;
}

.side-form-button>label {
    background-color: var(--frostedBackground);
    outline: var(--frostedBorder);
}

.side-content {
    width: 100%;

}

.selected-mode {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    ;
    border-radius: 9px;
    margin-top: -25px;
}

.smartBreak {
    font-size: 12px;
}

.dash-container {

    height: fit-content;
    width: 100%;


}

.dash-wrapper {


    border-radius: 20px;
    outline: var(--frostedBorder);

    height: fit-content;

}















.onboard-cell {
    display: grid;
    grid-template-columns: 1.5fr 1fr;

    padding: 70px 0px;
    margin: 0px 50px;
    border-radius: 30px;
}

.on-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 75px;
}

.on-intro-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.on-intro>p {
    font-size: var(--px15);
    color: white;
    margin-top: 5px;
    font-weight: 500;
    opacity: 0.7
}

.on-intro-text>h1 {
    font-size: 63px;
    text-transform: uppercase;
    text-align: center;
    line-height: 67px;
    max-width: 820px;
    margin: 13px 93px
}

.on-intro-text>p {
    text-align: center;
    max-width: 540px;
    font-size: var(--px15);
    color: #ffffff;
    line-height: 27px;
}

.feature-text>p {
    font-size: var(--px14);
}

.feature-text>p>a {
    color: var(--main-color);
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.feature-text>h3 {
    font-size: var(--px16);
    font-weight: 600;
}

.on-features {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    justify-content: center;
    padding-right: 75px;
    max-width: 510px;
}

.on-features-item>i {
    color: var(--main-color);
    font-size: 30px;
}

.on-intro-text>h1>span {
    color: var(--main-color);

}

.on-button>p {
    text-align: center;
    font-weight: 600;
    font-size: var(--px14);
    margin: 6px;
}

.feature-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.on-features-item {
    display: flex;
    align-items: center;
    gap: 20px;
}












.p-table-container {

    max-width: 1535px;

    border-radius: 16px;
    margin: auto;
    padding: 0px 60px;
}

.p-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 15px 26px;
    border-bottom: var(--frostedBorder);

}

.p-table-cell>h3 {
    font-size: var(--px14);
    font-weight: 500;
}

.p-table-cell>h3>span {
    font-weight: 600;
    font-size: var(--px12)
}

.p-table-header {
    background-color: var(--frostedBackgroundLarge);
    border-radius: 15px;
    border: var(--frostedBorder2);
}

.p-table-header>.p-table>.p-table-cell>h3 {
    font-weight: 600;
}

.p-table-body>.p-table:last-of-type {
    border-bottom: none;
}

.faucet-form {
    display: grid;
    padding: 0px 25px;
    align-items: center;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr 220px;
    margin-top: 40px;
}

.nav-bar>div>.glare-wrapper {
    border-radius: 50px !important;
}

.faucet-form>p {
    font-size: var(--px14);
}

.faucet-inputs {
    display: flex;
    align-items: center;
    gap: 15px;
}

.faucet-inputs>div>.glare-wrapper {
    border-radius: 50px !important;
}

.select-container {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    gap: 10px;
    border: none;
    font-size: var(--px14);
    font-weight: 600;
    cursor: pointer;

    outline: var(--frostedBorder);
    border-radius: 9px;
    cursor: pointer;
}

.select-container>select {
    background-color: transparent;
    border: none;
    padding: 5px 10px;
    outline: none;
    font-weight: 600;
    cursor: pointer;
}

.faucet-container {
    margin-top: 0px;
    margin-bottom: 25px;
}

.faucet-wrapper>p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--px14);
    padding: 0px 25px;
    gap: 7px;
    margin-top: 12px;
}

.int-activity-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0px 75px;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.int-activity {
    background-color: var(--frostedBackgroundLarge);
    display: flex;
    align-items: center;
    gap: 0px;
    padding: 35px 15px;
    border-radius: 14px;
}

.int-activity>i {
    margin: 0px 25px;
    color: var(--main-color);
}

.activity-text {}

.activity-text>h1 {
    font-size: 20px;
}

.activity-text>p {
    font-size: 16px;
    font-weight: 700;
    color: var(--main-color)
}

.activity-text>span {
    font-size: 13px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.492);
    margin-top: 10px;
    display: block;
}








.newsletter-container {
    margin-top: 0px;
}

.article-img {
    padding: 25px 0px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.article-img>img {
    width: 100%;
    border-radius: 13px;
    height: 210px;
    object-fit: scale-down;
    outline: var(--frostedBorder2);
    background-color: #050e14;
}

.demo-img>img {
    object-fit: cover;
}

.article-text>h4 {
    font-size: 16;
    font-weight: 600;
    margin-top: 10px;
    text-transform: uppercase;
    color: var(--main-color);
}

.compact-article {
    padding: 0px 74px !important
}

.article-container {
    display: grid;
    grid-template-columns: 410px 410px 410px;
    gap: 35px;
    padding: 0px 55px;
    padding-bottom: 40px;
    justify-content: center;
}

.article-item {
    outline: var(--frostedBorder);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    background-size: cover;
    background-position: bottom;
    cursor: pointer;
    padding: 22px;
    background-color: var(--frostedBackgroundSolid);
    border: var(--frostedBorder2);

}

.article-text {
    display: flex;
    flex-direction: column;
    gap: 0px;
    backdrop-filter: blur(15px);
    border-radius: 0px 0px 12px 12px;
    ;
    padding-top: 8px;
}

.article-text>h3 {
    font-size: var(--px15);
    font-weight: 600;
}

.article-text>p {
    font-size: var(--px14);
    font-weight: 500;
}

.article-text>span {
    font-size: var(--px12);
    font-weight: 500;
}











.int-queue {
    display: flex;
    justify-content: center;
}

.int-queue-container {
    display: flex;
    gap: 10px;
    padding: 15px 40px;
    border: var(--frostedBorder);
    border-bottom: none;
    margin-top: -1px;
    border-top: none;
    /* margin-bottom: -32px; */
}

.queue-loader {
    width: 27px;
    object-fit: scale-down;
}

.int-status {
    max-width: 14rem;
}

.int-status>h2 {
    font-size: var(--px14);
    font-weight: 600;
}

.int-status>p {
    font-size: var(--px13);
    font-weight: 500;
}

.int-strip {
    display: flex;
    justify-content: space-between;
    padding: 0px 75px;
    margin-bottom: -10px;
}

.int-action {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    width: 300px;
    gap: 10px;
}

.int-action>button {
    width: 220px;
    padding: 13px 20px;
}

.int-action>p {
    font-size: var(--px14);
    font-weight: 500;
    text-align: right;
}

.int-desc {
    height: fit-content;
}

.int-desc>h3 {
    font-size: var(--px15);
    font-weight: 600;
}

.int-desc>h2 {
    font-size: 48px;
    font-weight: 700;
}

.int-desc>h2>span {
    font-weight: 600;
    font-size: 25px;
    color: var(--main-color);
}

.int-desc>p {
    font-size: var(--px15);
    font-weight: 600;
    margin-top: -5px;
}

.int-strip {
    display: flex;
    align-items: center;
    height: 200px;
}

.quick-links {
    display: flex;
    gap: 10px;
}

.quick-links>a {
    font-size: var(--px14);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 7px;
}

.quick-links>a>i {
    font-size: var(--px16);
}

.quick-links>a:hover {
    opacity: 0.5;
}


.m-615af6c9 {

    font-size: 34px !important;
    font-weight: 600 !important;
    margin: 19px 10px;
}

.login-desc {
    display: flex;
    padding: 0px 75px;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    flex-direction: column;
    margin-top: -50px;
}

.mantine-Modal-close {
    margin-right: 30px;
}

.mantine-Modal-close:hover {
    background-color: var(--frostedBackgroundSolid);

}

.login-desc>button {
    width: 250px;
    padding: 13px 24px;
    height: 50px;
}

.login-desc>h2 {
    font-size: var(--px15);
    font-weight: 500;
    opacity: 0.5;
}


article>header>img {
    width: -webkit-fill-available;
    height: 45vh;
    object-fit: cover;
    /* position: absolute; */
    /* margin-right: 45px; */
    /* border-radius: 0px 20px 0px 0px; */
    filter: brightness(0.7);
    /* display: none; */
    margin-top: 0px;
    filter: brightness(0.5);
}

.top-text {
    position: relative;
    height: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 13;
    padding: 0px 0px;
    justify-content: center;
    gap: 7px;
    max-width: 750px;
    margin: auto;
    padding-right: 90px;
    padding-top: 0px;
}

.top-text>a {
    font-size: var(--px14);

    cursor: pointer;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.409)
}

.top-text>a:hover {
    color: white;
    text-decoration: underline;
}

.top-text>h1 {
    font-size: 42px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    line-height: 53px;
    font-weight: 500;
}

.news-category {
    font-size: 16px;
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: -8px;

}

article>section {
    max-width: 750px;
    margin: auto;
    word-break: break-word;
}

section>div>p {
    position: relative;
    z-index: 1;
}

.keywords>span {
    font-weight: 600;
    font-size: var(--px15);
}

.top-text>p>span {
    font-weight: 500;
    font-size: var(--px15);
}

section>div {
    margin-bottom: 100px;
}

section>div>p,
section>div>ul,
section>div>ol {
    line-height: 35px;
}

.news-headerImg {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

.news-headerImg>img {
    width: 100%;
    max-width: 750px;
    outline: var(--frostedBorder2);
    border-radius: 20px;
}


section>div>ul {
    list-style: disc;
    padding-left: 18px;
}

section>div>ol {
    padding-left: 18px;
}



.back-btn {
    margin: 50px;
    position: fixed;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    background-color: var(--frostedBackground);
    border: var(--frostedBorder);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 15;
    cursor: pointer;
}

.spinnerCss {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-animation: spinner 10s linear infinite;
    -moz-animation: spinner 10s linear infinite;
    animation: spinner .8s linear infinite;
}

.bolt-notice-block {
    position: fixed;
    right: 0px;
    bottom: 0px;
    width: 240px;
}

.bolt-notice {
    display: flex;
    align-items: center;
    gap: 7px;
    border-radius: 39px;
}

.bolt-notice>i {
    color: var(--main-color);
    font-size: 27px;

}

.bolt-text>h3 {
    font-size: 13px;
    font-weight: normal;
}

.bolt-text>h2 {
    font-size: 18px;
    font-weight: normal;
    margin-top: -4px;
}

.bolt-text>h2>a {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--main-color);
}

.bolt-text>h2>a:hover {
    text-decoration: underline;
}

.bolt-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 10px 0px;
    border-top: var(--frostedBorder2);
    margin-top: 10px;
    width: 100%;
    opacity: 0.6;
}

.bolt-button>i {
    font-size: 17px;
    color: white;
}

.bolt-button>span {
    font-size: 13px;
    color: white;
}

.bolt-button:hover {
    opacity: 1;
}


.bento-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 1300px;
    margin: auto;
}

.bento-flow {
    display: grid;
    grid-template-columns: 1.5fr 2.5fr;
    gap: 25px;
    padding: 0px 75px;

}

.bento-title {

    font-size: 45px;
    padding: 0px 75px;
    margin: 23px 0px;
}

.bento-flow-alt {
    display: grid;
    grid-template-columns: 2.5fr 1.5fr;
    gap: 25px;
    padding: 0px 75px;

}

.bento-cell {
    background-color: var(--frostedBackgroundSolid);
    width: 100%;
    height: 310px;
    border-radius: 14px;
    padding: 27px;
    transition: 0.2s ease;
    outline: solid 1px transparent;
}

.single-mode-btn>div {
    border-radius: 150px;
}

.bento-cell:hover {
    outline: var(--frostedBorder2)
}

.logo-strip>img {
    width: 45px
}

.logo-activity-strip {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.logo-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.logo-activity-left>p {
    font-size: var(--px14);
    font-weight: 500;
    color: var(--text-gray);
    margin-bottom: -7px;
}

.logo-activity-left>h2 {
    font-size: 30px;
    font-weight: 600;
}

.logo-stencil {
    width: 210px;
    position: absolute;
    left: 207px;
    margin-top: -16px;
    opacity: 0.1;
}

.charm-price>i {
    color: var(--main-color);
}

.charm-price {
    display: flex;
    align-items: center;
    gap: 10px;
    border: var(--frostedBorder2);
    padding-top: 5px;
    padding-bottom: 3px;
    padding-left: 22px;
    padding-right: 36px;
    border-radius: 150px;
    width: fit-content;
}

.charm-price-text>p {
    font-size: var(--px12);
    font-weight: 500;
    color: var(--text-gray);
    margin-bottom: -5px;
}

.charm-price-text>h2 {
    font-size: 13px;
    font-weight: 600;
}


.progress-marker {
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: white;
    border-radius: 50px;
    /* or any color you prefer for the marker */
    transform: translateX(-50%);
    /* Centers the marker on the specified position */
}

.progress-marker-text {
    position: absolute;
    top: -25px;
    /* Adjust as necessary to position the text above the marker */
    color: white;
    /* or any color that matches your design */
    font-size: 11px;
    /* Adjust the font size as needed */
    white-space: nowrap;
    font-weight: 600;
    /* Prevents text from wrapping */
}

[data-mantine-color-scheme=light] .m-db6d6462 {
    --_track-bg: var(--frostedBackground) !important;
}

.reward-progress {
    display: flex;
    flex-direction: column;
    gap: 17px
}

.reward-progress-title {
    display: flex;
    align-items: flex-start;
    gap: 7px;
    justify-content: center;
}

.reward-progress-counter>h2 {
    font-size: 35px;
    font-weight: bold;
    height: 44px;
}

.reward-progress-title>i {
    color: var(--main-color);
    font-size: 35px;
    margin-top: 10px;
    margin-left: -15px;
}

.reward-progress-bar {
    accent-color: var(--main-color);
    margin: 20px 0px
}

.reward-progress-counter>p {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-gray);

}



.reward-status {
    display: flex;
    justify-content: space-between;
}

.small-charm-price>p {
    font-size: var(--px12);
    font-weight: 500;
    color: var(--text-gray);
    margin-bottom: -5px;
}

.small-charm-price>h2 {
    font-size: 14px;
    font-weight: 600;
}

.reward-status-data {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rewards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.reward-status-text {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.reward-status-text>h2 {
    font-size: 25px;
    font-weight: 700;
}

.reward-status-text>p {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-gray);
}

.reward-status-data>.charm-price {
    background-color: var(--frostedBackground);
    border: none;
}

.manage-container {
    display: grid;
    grid-template-columns: 1.5fr 1px 1fr;
    height: 100%;
    gap: 25px;
}

.manage-progress-bar {
    display: flex;
    flex-direction: column;
    margin: 22px 0px;
    gap: 26px;
}

.vert-separator {
    width: 1px;
    background-color: white;
    height: 100%;
    opacity: 0.07;
}

.horiz-separator {
    height: 1px;
    background-color: white;
    width: 100%;
    opacity: 0.07;
}

.magage-progress-title {
    display: flex;
    align-items: center;
    gap: 7px;
}

.magage-progress-title>h2 {
    font-size: 32px;
    font-weight: 700;
}

.magage-progress-title>i {
    color: var(--alt-color);
    font-size: 35px;


}

.manage-progress {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.manage-progress>h2 {
    font-size: 22px;
    font-weight: 700;
}

.text-chips {
    display: flex;
    ;
}

.text-chip>p {
    font-size: 13px;
    font-weight: 500;
    color: var(--text-gray);


}

.unstake-btn {
    background-color: #FF7612;
    padding: 14px 26px;
    border-radius: 50px;
    color: white;
    font-weight: 600;
    font-size: var(--px16);
    cursor: pointer;
    transition: 0.2s ease;
    text-transform: uppercase;
    color: var(--base-color);
    border: none;
    margin-top: 29px;
    margin-bottom: 18px;
}

.disabled-btn {
    background-color: var(--frostedBackground);
    color: var(--text-gray);
    cursor: not-allowed;
}

.manage-button>h2 {
    font-size: 17px;
    font-weight: 600;
    color: white;


}

.unstake-info {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-gray);
}

.scan-strip {
    display: flex;
    gap: 30px;

}

.scan-strip {
    margin: auto;
    padding: 0px 60px;
    max-width: 1535px;
    opacity: 0.4;
    transition: 0.2s ease;
}

.scan-strip:hover {
    opacity: 1;
}

.scan-strip>div {
    display: flex;
    gap: 7px;
}

.scan-strip>div>a {
    font-size: 13px;
    font-weight: 500;
    color: var(--text-gray);
    display: flex;
    align-items: center;
    gap: 7px;

}

.scan-strip>div>a:hover {
    color: var(--main-color);
    text-decoration: underline;
}

.scan-strip>div>p {
    font-size: 12px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    gap: 7px;

}


@-moz-keyframes spinner {
    100% {
        -moz-transform: rotate(0deg);
    }
}

@-webkit-keyframes spinner {
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes spinner {
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.theme-toggle {
    position: fixed;
    bottom: 55px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    width: 250px;
    border-bottom: var(--frostedBorder);
    transition: 0.2s ease;
    padding-left: 25px;
}

.theme-toggle>p {
    font-size: var(--px12)
}

.theme-toggle:hover {
    opacity: 1;
}

.theme-item>input {
    display: none;
}

.theme-item>label>p {
    font-size: var(--px14);
    color: rgba(255, 255, 255, 0.423);
    transition: 0.2s ease;
    cursor: pointer;
    padding-bottom: 8px;
}

.theme-item>input:checked~label>p {
    border-bottom: solid 1.5px white;
    color: white;
}


@media only screen and (max-width: 1200px) {
    .hero-background {
        opacity: 0 !important;
    }

}

@media only screen and (max-width: 1280px) {
    .bolt-notice-block {


        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 150;
    }

    .bolt-button {
        display: none;
    }

    .bolt-notice {
        background-color: rgb(18 29 33);
        padding: 10px 13px;

    }
}

@media only screen and (max-width: 1300px) {
    .onboard-cell {
        grid-template-rows: 1.5fr;
        grid-auto-flow: column;
        grid-template-columns: 1fr !important;
    }

    .on-features {
        display: none !important;
    }

    .footer-inner {
        flex-direction: column;
    }

    .accord-container {
        padding: 0px 17px
    }

    .user-items {
        padding: 0px 17px;
    }

    .flex-card-container {
        height: auto;
    }

    .side-button>label {
        width: 58px !important
    }

    .side-button>label>i {
        display: none;
    }

    .side-buttons {
        min-width: auto;
        width: 50px;
    }

    .side-button>label>p {
        display: none;
    }

    .side-button {
        width: 50px;
    }

    .side-logo {
        display: none;
    }

    .vert-divider {
        display: none;
    }

    .theme-toggle {
        flex-direction: column;
        width: 67px;
        gap: 0px;
    }

    .theme-item>input:checked~label>p {
        border: none;
        font-weight: 600;
    }

    .theme-toggle>p {
        display: none;
    }
}