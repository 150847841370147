.loading-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    backdrop-filter: blur(20px);
}

.loading-cell {
    background-color: var(--glassBackground);
    border: var(--authFrostedBorder);
    border-radius: 15px;
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
}

.cell-body {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 5px 10px;

}

.loading-spinner>img {
    width: 80px;
    pointer-events: none;
}

.cell-body>h2 {
    font-size: 16px;
    font-weight: 600;
    color: white;
    margin-right: 10px;

}

.cell-header {
    border-bottom: var(--authFrostedBorder);
    margin-bottom: 15px;
}

.cell-header>h4 {
    font-size: 13px;
    font-weight: 600;
    color: white;
    margin-bottom: 10px;
    text-align: center;
    opacity: 0.8;
}

.disabled-click-table:hover {
    background-color: transparent;
    cursor: default;
}

.chain-switcher {
    margin: 0px 24px;
    margin-top: 25px;
}

.chain-list {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.chain-right {
    display: flex;
    align-items: center;
}

.chain-text>span {
    font-size: 12px;
    font-weight: 500;
    color: white;
    opacity: 0.8;
    margin-bottom: -2px;
}

.chain-wrap {
    background-color: transparent;
    border: none;
    border-radius: 12px;
}

.disabled-chain {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    ;
}

.selected-chain {

    outline: solid 3px var(--main-color);
    border-radius: 16px;
}

.selected-chain>.chain-btn>.chain-left>img {}

.chain-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 11px;
    border-radius: 12px;
}

.chain-btn:hover {
    background-color: var(--frostedBackground);
}

.chain-right {
    margin-right: 10px;
}

.chain-left {
    display: flex;
    align-items: center;
    gap: 15px
}

.chain-left>img {
    width: 45px;
    height: 45px;
    object-fit: scale-down;
    padding: 7px;
    background-color: var(--frostedBackground);
    border-radius: 10px;
    border: var(--authFrostedBorder)
}

.chain-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.chain-text>h3 {
    font-size: 15px;
    font-weight: 600;
    color: white;
}

.chain-header {
    padding: 0px 30px;
    background-color: var(--base-gray);
    height: 115px;
    align-items: center;
    display: flex;
    position: sticky;
    top: -35px;
    backdrop-filter: blur(20px);
    z-index: 40;
    padding-top: 40px;
}

.chain-header>h1 {
    font-size: 30px;
    font-weight: 700;
    height: 50px;
    line-height: 48px;
}

.meta-inline-container {
    width: 220px;
    height: 100px;
    display: flex;
    align-items: center;
    position: sticky;
    bottom: 0px;

}

.floating-header {
    position: fixed;
    margin-left: 25px;
}

.toggle-network>img {
    width: 40px;
    height: 40px;
    object-fit: scale-down;
    padding: 5px;
    background-color: var(--frostedBackground);
    border-radius: 50%;
    margin-left: -7px
}

.selected-chain>div>.chain-left>img {}

.toggle-network {
    display: flex;
    background-color: var(--frostedBackgroundSolid);
    border: var(--authFrostedBorder);
    align-items: center;
    gap: 17px;
    padding: 12px 26px;
    border-radius: 12px;

}

.toggle-network>h3 {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.toggle-network>h3>p {
    font-size: 12px;
    font-weight: 500;
    color: white;
    opacity: 0.8;
    margin-top: -2px
}

.mantine-Popover-dropdown {
    background-color: transparent !important;

    box-shadow: none;
    padding: 0px;
    margin-left: -20px;
}











.meta-btn-container>div {
    display: flex;
    justify-content: center;
}





















.auth-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.522);
    height: 100vh;
    z-index: 9999
}

span>b {
    color: black;
}

.select-modal>div:last-of-type {
    border-radius: 25px !important;
}

.auth-window {
    width: 400px;
    height: 600px;
    background-color: var(--base-gray);
    border-radius: 35px;
}

.lg-window {


    display: block !important;
    flex-direction: column;
    max-height: 488px !important;
    overflow-y: scroll;
    border: var(--authFrostedBorder);
    height: auto;
}

.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
}


.promo-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    margin: auto;
    max-width: 380px;
}

.promo-screen {
    display: grid;
    grid-template-rows: 100px 1fr 80px;
    height: 100%;

}

.promo-action>i {
    font-size: 60px;
    ;
}

.promo-action>p {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    width: 246px
}

.promo-action>h1 {
    font-size: 36px;
    font-weight: 700;

    text-align: center;
    line-height: 48px;
}

.promo-action>img {
    width: 100px;
    margin-bottom: 10px;
    background-color: var(--frostedBackground);
    border-radius: 50%;
    object-fit: scale-down;
    padding: 15px
}

.auth-window {
    display: grid;
    grid-template-rows: 1fr 140px;
    border: var(--authFrostedBorder);
}

.meta-btn-container {
    display: flex;
    justify-content: center;
    align-items: space-between;
    flex-direction: column;
    margin: 0px 60px;
}

.meta-btn-container>button {
    border: none;
    background-color: transparent;
    padding: 10px 25px;
    margin-top: 10px;
}

.testbox {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 500px;
    height: 500px;
    background-color: green;
    z-index: 9999;
}

.connecting-notice {
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    width: 100%;
    height: 100vh;
    backdrop-filter: blur(30px);

}

.con-text {
    font-size: 15px;
}

.meta-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    background-color: var(--frostedBackgroundSolid);
    width: 100%;
    border: none;
    padding: 15px 20px;
    border-radius: 50px;
    color: white
}

.meta-btn:hover {
    background-color: var(--main-color);
    color: black
}

.meta-btn>i {
    color: white;
    margin-right: 10px;
}

.meta-btn>img {
    width: 30px;
    margin-right: 10px;
}

.tip-strip {
    display: flex;
    font-size: 14px;
    gap: 6px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: -34px;
}









.auth-back-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: transparent;
    border: var(--frostedBorder);
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 50px;
    font-weight: 600;
    margin: 20px 0px;
    margin-top: 30px;
    padding-right: 20px;
    margin-bottom: 30px;
    background-color: var(--frostedBackground);

}

.auth-back-btn:hover {
    background-color: var(--frostedBackground);
}

.auth-back-btn>i {
    font-size: 20px;
}










.mantine-Popover-arrow {
    display: none;
}


.mantine-Modal-content {
    background-color: var(--base-gray);
}

.mantine-Modal-header {
    background-color: var(--base-gray);
    height: 110px;
    margin: 6px 10px;
    margin-top: 0px;
    top: 0px !important;
    left: 10px !important;
}

.mantine-Modal-close {
    color: white;
    background-color: transparent;
    padding: 2px;
    width: 40px;
    height: 40px;
    border-radius: 7px;
    margin-right: 15px;
}

.mantine-ScrollArea-viewport::-webkit-scrollbar {
    width: 10px;
    background-color: white;
}

.mantine-TagsInput-pill {
    background-color: var(--frostedBackground);
    color: white;
    border: var(--authFrostedBorder);

}

.mantine-TextInput-wrapper {
    background: transparent !important;
}

.mantine-TextInput-input {
    background: transparent !important;
    color: white;
    border-color: #ffffff26;
}

.mantine-Input-input {
    background-color: var(--frostedBackgroundSolid) !important;
}

.mantine-PillsInputField-field {
    width: 390px;
}

.mantine-Modal-close:hover {
    color: white;
    background-color: var(--frostedBackground) !important
}

.mantine-Modal-title {
    font-size: 31px;
    font-weight: 700;
}











.close-btn-container {
    position: absolute;
    width: 100%;
    display: flex;
    padding: 15px;
}

.close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    width: 50px;
    height: 50px;
    background-color: var(--frostedBackgroundSolid);
    border: none;
    border-radius: 50px;
}