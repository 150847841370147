@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700,800);

:root {
    --main-color: #12B1FF;
    --alt-color: #FF7612;
    --base-color: #020E14;
    --light-base-color: rgba(18, 177, 255, 0.07);
    --base-gray: #050e14;
    --off-gray: #e9e9e9;
    --item-gray: #9D9D9D;
    --text-gray: #c6c9cc;
    --trans: #ffffff00;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --titleFont: 2.2rem;
    --hoverBorder: rgb(231, 231, 231);
    --frostedBorder: solid 1.5px rgba(255, 255, 255, 0);
    --frostedBackgroundLarge: rgba(255, 255, 255, 0.039);
    --frostedBackgroundDark: rgba(0, 0, 0, 0.11);
    --frostedBorder2: solid 1.5px #ffffff14;
    --frostedBackgroundSolid: rgb(15 27 32);
    --heroFont: 3.6rem;
    --frostedBackground: rgba(255, 255, 255, 0.039);
    --authFrostedBorder: solid 1px #ffffff14;
    --glassBackground: #0f0f0fbd;
    ;
    --gradientedFrostedBorder: solid 1.5px;
    --mantine-color-gray-3: #ffffff14 !important;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: white;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

option {
    background: var(--base-color);

}

.material-icons {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(211, 0, 0);
}

html,
body {}


#root {
    transition: 0.2s ease;
}

.back-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons-rounded {
    font-family: 'Material Icons Round' !important;
    font-weight: normal;
    font-style: normal;
    font-size: 1.5rem;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--base-color) !important;
    padding: 0px;
    background-image: url(/public/assets/backStencil.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

button {
    cursor: pointer;
}

.landing-container {
    background-size: cover;
    height:
        --webkit-fill-available;

    ;

    margin: auto;
    margin-top: -100px;
}

.nav-left>img {
    width: 145px;
    cursor: pointer;
}

.mobile-nav>img {
    width: 145px;
    cursor: pointer;
}

.nav-left>img:hover {
    opacity: 0.8;
}

nav {
    display: flex !important;
    align-items: center;
    width: 100%;
    padding: 25px 50px;
    border-bottom: var(--frostedBorder);
    justify-content: space-between;
    position: sticky;
    transition: 0.2s ease;
    z-index: 15;
    top: 0px;

}

.mobile-nav>img {
    cursor: pointer;
}

.scrolled {
    background-color: var(--base-color);
}

.nav-left {
    display: flex;
    gap: 50px;
}

.nav-left>ul {
    display: flex;
    list-style-type: none;
    color: white;
    gap: 30px;
    font-weight: 600;
    align-items: center;

}

.nav-left>ul>li>a {
    transition: 0.5s ease;
    font-size: 15px;
    font-weight: 500;

}

.nav-left>ul>li {
    display: flex;
    align-items: center
}

.nav-left>ul>li>i {

    font-size: 17px;
    margin-top: 2px;
    margin-right: 5px;
    margin-left: 7px;

}

.focused-nav {
    color: var(--main-color) !important;


}

.focused-nav>a {
    color: var(--main-color) !important;

}

.hero {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-center {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 5%;
    gap: 8px;

}

.hero-button {
    background-color: var(--main-color);
    padding: 14px 45px;
    border-radius: 50px;
    color: white;
    font-weight: 600;
    font-size: var(--px16);
    cursor: pointer;
    transition: 0.2s ease;
    text-transform: uppercase;
    color: var(--base-color);
    border: none;
    width: fit-content;
}

.poweredBy>img {
    width: 25px;
}

.hero-center>h1 {
    font-size: 63px;
    font-weight: 700;
    text-align: center;
    margin-bottom: -5px;
    text-transform: uppercase;
    line-height: 80px;
    max-width: 800px;
    margin-top: 45px;
}

.hero-center>h1>span {
    font-weight: 700;
    color: var(--main-color);
    font-size: 63px !important;
}

.hero-center>p {
    font-size: var(--px16);
    font-weight: 500;
    max-width: 350px;
    text-align: center;
    color: white;
    line-height: 30px;
}

.hero-center>p>span {
    font-weight: 700;
    color: var(--main-color);
}

.poweredBy {
    display: flex;
    align-items: center;
    gap: 10px;
}


.banner-notice {

    width: 100%;
    position: sticky;
    top: 0px;
    z-index: 50;

}

.banner-notice>p {
    text-align: center;
    background-color: var(--main-color);
    color: black;
    padding: 9px 33px;
    font-weight: 500;
    border-radius: 0;
    margin-bottom: 65px;
}

.banner-notice>p>strong {
    font-weight: 700;
    color: black;
}


.poweredBy>p {
    font-size: 13px;
    color: var(--text-gray);
}

.metamask-btn {
    background-color: var(--frostedBackgroundSolid);
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    padding: 8px 18px;
    border-radius: 25px;
    gap: 7px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ease;
    outline: solid 1.5px transparent;
}

.metamask-btn:hover {
    background-color: var(--base-color);
    outline: var(--frostedBorder2);
}

.metamask-btn>img {
    width: 30px;
}


.ribbon-item {
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ribbon-item>div {
    width: fit-content;
}

.ribbon-item>img {
    width: 75px;
}

.ribbon-item>h2 {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 10px;
}

.ribbon-item>h2>span {
    font-weight: 700;
    color: var(--main-color);
}

.home-ribbon {
    background-color: var(--frostedBackgroundLarge);
    display: flex;
    border-radius: 30px;
    align-items: center;
    gap: 10px;
}

.home-ribbon-container {
    padding: 20px 60px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.ribbon-item>ul {
    list-style-type: none;
    font-size: var(--px14);
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 22px;
}

.ribbon-item>ul>li {
    color: var(--text-gray);
}

.vertical-divider {
    height: 250px;
    background-color: rgba(255, 255, 255, 0.086);
    width: 1px;
}


.hero-center>div>.glare-wrapper {
    border-radius: 50px !important;
}


.home-text {
    height: 570px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.home-text-wrapper {
    max-width: 800px;
    text-align: center;
}

.home-text-wrapper>h1 {
    font-size: 50px;
    text-transform: uppercase;
}

.home-text-wrapper>h1>span {
    font-weight: 700;
    color: var(--main-color);
}

.home-text-wrapper>p {
    font-size: var(--px16);
    font-weight: 500;
    ;
    line-height: 35px;
    margin-bottom: 10px;
}

.home-text-wrapper>p>a {
    color: var(--main-color);
    font-weight: 600;
}

.home-text-wrapper>p>a:hover {
    text-decoration: underline;
}


[data-mantine-color-scheme=light] .m-4ba585b8 {
    padding-right: 40px !important;
}



.flex-card-container {
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    gap: 40px;
    height: 100vh;
    align-items: center;
    justify-content: center;

}

.flex-card-container>div>div>.glare-wrapper {
    border-radius: 30px !important;
}

.flex-card {
    background-color: var(--frostedBackgroundSolid);
    outline: var(--frostedBorder2);
    padding-bottom: 0px;
    border-radius: 30px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-text {
    padding: 100px 80px;
    padding-bottom: 0px;
}

.flex-text>i {
    font-size: 50px;
    color: var(--main-color);
}

.flex-text>h2 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 10px;
}

.flex-text>p {
    font-size: var(--px16);
    font-weight: 500;
    line-height: 30px;
    max-width: 530px;
}

.flex-button {
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
    align-items: center;
    gap: 10px;
    border: none;
    cursor: pointer;
}

.flex-button>span {
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}

.flex-button>i {
    color: var(--main-color);
    font-size: 30px;
}

.flex-button-wrapper {
    display: flex;
    justify-content: flex-end;
    height: fit-content;
    margin-top: 20px;
}

[data-mantine-color-scheme=light] .m-4ba585b8 {
    background-color: transparent !important;
}

.mantine-NativeSelect-input {
    background-color: var(--frostedBackground) !important;
    border: none !important;
    color: white !important;
    height: 43px !important;
}



















.nav-links {}

.nav-spacer {
    height: 100px;
}

.navbar {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 50px 80px;
}

.navbar-container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 150;
    transition: 0.1s ease;
}

/* .nav-scrolled {
    backdrop-filter: blur(700px);
    border-bottom: var(--frostedBorder);
} */


/* width */
::-webkit-scrollbar {
    width: 7px;
    margin-right: 10px;
    padding-right: 10px;
    display: none;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
    border: none;
    border-radius: 3px;
    margin-top: 25px;

}

/* Handle */
::-webkit-scrollbar-thumb {

    border-radius: 8px;
    margin-top: 6px;
    transition: 0.2s ease;
    outline: var(--frostedBorder);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: white;
}



ul {
    list-style-type: none;
}

.logo {
    display: flex;
    align-items: center;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 30px;
}

.nav-links>li {
    font-size: var(--px15);
    font-weight: 600;
    cursor: pointer;
}

.logo>img {
    width: 110px;
}

.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ;
    padding: 50px 80px;
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 2
}

.mid-hero {
    display: flex;
    justify-content: space-between;
}

.hero-text>h1 {
    font-size: var(--heroFont);
    font-weight: 700;
    text-wrap: balance;
    line-height: 10px;
    line-height: 70px;
}

.hero-text>p {
    font-size: var(--px17);
    font-weight: 500;
    line-height: 25px;
}

.hero-text>h1>span {
    font-weight: 800;
}

.floating-text>h2 {
    font-size: 209px;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    color: transparent;
    margin-top: -33vh;
    opacity: 0.15;
}

.button {

    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 36px;
    gap: 10px;
    border: none;
    width: fit-content;
    font-size: var(--px14);
    font-weight: 600;
    cursor: pointer;
    text-transform: uppercase;

    color: var(--base-color) !important;

}

.button>span {
    cursor: pointer;
    display: flex;
    gap: 7px;
    color: var(--base-color);
    text-transform: uppercase;
    white-space: nowrap;
}

.button>span>p {
    font-weight: 500;
    display: flex;
    color: var(--base-color);
}

.button {
    position: relative;

    border-radius: 50px;
    transition: 0.2s ease-in;
}


.price-list{
    width: 200px;
}

.price-item {
  display: flex;
  font-size: 13px;
}

.price-item > span{
  display: flex;
  flex: 1;
  align-items: center;
}

.price-item > span:before {
  content: '';
  border-bottom: 2px dotted #AAA;
  flex:1;
  margin: 0 10px;
}

.btn-invert {
    background-color: var(--frostedBackgroundLarge) !important;
    color: white !important;
    border-radius: 50px;
}

.btn-invert>span {
    color: white;
}

.on-button-group {
    display: flex;
    width: 100%;
    gap: 15px;
    margin: 10px 0px;
}

.on-button-group>a {
    border-radius: 50px;
}

.on-button-group>div>.glare-wrapper {
    border-radius: 50px !important;
}



.on-button-group>div:last-of-type>a>button>span {
    color: white;
}

.on-button-group>div:last-of-type>a>button {

    background-color: transparent;
    outline: var(--frostedBorder2);

}


.on-button-group>div:last-of-type>button {
    background-color: transparent;
    outline: var(--frostedBorder2);
}

.on-button-group>div:last-of-type>button>span {
    color: white;
}


.floating-side {

    position: fixed;
    background-color: transparent;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    right: 63px;
    z-index: 150;
    top: 37px;
    backdrop-filter: blur(9px);
}




.floating-side>div>div>label {
    padding: 25px;

}

.floating-side>div>div>label>span {
    margin-left: 5px;
    font-size: var(--px12);
}







.hero-text {
    margin-top: -20px;
}

.button>img {
    width: 25px;
    ;
}

.section-bulb {
    width: 100%;

    background-size: cover;

    background-position: center;
    background-repeat: no-repeat;

    display: grid;
    grid-template-columns: 1fr 29.5%;
    position: relative;
}

.section-bulb>img {
    width: 100%;
    height: auto;
    object-fit: contain;
    position: absolute;
}

.bulb-container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 94vw;
    align-items: center;
    justify-content: center;
    padding: 5px 87px;
    padding-left: 0px;
    margin-left: -7px;

}

.bulb-list {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    gap: 45px;
    justify-content: space-evenly;
    margin: 65px;
}

.bulb-title {
    width: 50%;
    text-align: center;



}

.bulb-title>h2 {
    font-size: var(--heroFont);
    font-weight: 700;
    text-wrap: balance;
    line-height: 10px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 8px;
}

.bulb-title>h2>span {
    font-weight: 800;
}

.bulb-title>p {
    font-size: var(--px17);
    font-weight: 500;
    line-height: 25px;
}

.bulb-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: var(--frostedBorder);
    padding: 40px 25px;
    gap: 17px;
    border-radius: 20px;
}

.bulb-icon>img {
    width: 65px
}

.bulb-icon {
    padding: 28px;
    background-color: var(--frostedBackground);
    width: fit-content;
    height: fit-content;
    border-radius: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bulb-container>h3 {
    font-size: 209px;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    color: transparent;
    opacity: 0.15;
    margin-top: -60px;
}

.center-text {

    display: flex;
    flex-direction: column;
    gap: 5px;

}

.center-text>p {
    font-size: var(--px14);

}

.notif-wrapper {
    position: fixed;
    bottom: 67px;
    right: 65px;
    z-index: 99999999999999999999999999;

}

.notif-container {
    width: 296px;
    display: none;
    backdrop-filter: blur(12px);
    border: var(--frostedBorder);
    padding: 17px;
    border-radius: 15px;
    gap: 15px;
    border: var(--frostedBorder2);
}

.notif-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.notif-body>h3 {
    font-weight: 600;
    font-size: var(--px15);
}

.notif-body>p {
    font-weight: 500;
    font-size: var(--px14);
}

.notif-icon {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.notif-title>button {
    display: flex;
    height: 100%;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.notif-title>button:hover {
    opacity: 0.5;
}

.input-field {
    width: 100%;
    padding: 12px 30px;
    border-radius: 50px;
    border: var(--frostedBorder);
    background-color: var(--frostedBackground);
    color: white;
}

.input-field::placeholder {
    color: rgba(255, 255, 255, 0.704);
}

.input-field:focus {
    outline: none;
}

.article-form {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    border-radius: 12px;
    padding: 20px;
    width: 100%;
    margin: auto;
}

.article-form>label {
    font-weight: 600;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.article-form-title {
    font-size: 40px;
    font-weight: 700;
}

.editor-for-newsarticles {
    display: flex !important;
    flex-direction: column;
    height: 500px !important;
    border-radius: 12px;
    background: white;
    padding: 20px;
    border: solid 1px #eeeeee;
    box-sizing: border-box;
}

.editor-for-newsarticles * {
    color: black !important;
}


.drop-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    margin-bottom: 0px !important;

}

.dropzone * {
    color: black;
}



.multiSelect {
    width: 100%;
}

.multiSelect * {
    color: black;

}



/* .mantine-Menu-dropdown {
    background-color: var(--frostedBackground) !important;
    outline: var(--frostedBorder2) !important;
    border: none !important;
} */

.mantine-Textarea-input {
    background-color: var(--frostedBackground) !important;
    outline: none;
    border: none !important;
    color: white !important;

}

.mantine-Menu-item {
    background-color: transparent !important;
}

.mantine-Menu-item:hover {
    background-color: var(--frostedBackground) !important;
}

.mantine-Textarea-input:focus {
    outline: var(--frostedBorder2) !important;
}

.mantine-TagsInput-input {
    background-color: var(--frostedBackground) !important;
    outline: none;
    border: none !important;
    color: white !important;
    padding: 10px 20px !important;


}

.mantine-FileInput-root {
    background-color: var(--frostedBackground);
    border-radius: 4px;
}

.mantine-FileInput-input {
    background-color: transparent !important;
    outline: none;
    border: none !important;
    color: white !important;
    padding: 10px 20px !important;
}

.mantine-TextInput-input {
    background-color: var(--frostedBackground) !important;
    outline: none;
    border: none !important;
    color: white !important;
}

.mantine-TextInput-input:focus {
    outline: var(--frostedBorder2) !important;
}

[data-mantine-color-scheme=light] .m-44da308b {
    background-color: var(--frostedBackground) !important;
}

.article-form-input {
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.article-form-input>label {
    font-weight: 600;
    font-size: 16px;
}

.article-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.editor-for-newsarticles {
    background-color: var(--frostedBackground);
    border: none;
    border-radius: 5px;
}

.editor-for-newsarticles * {
    color: white !important;
}

.rdw-editor-toolbar {
    background-color: transparent !important;
    border: none !important;
}

.rdw-option-wrapper {
    background-color: var(--frostedBackground) !important;
    border: none !important;
    height: 30px !important;

}

.rdw-option-wrapper>img {
    filter: invert();
    padding: 5px !important;
}

.rdw-dropdown-wrapper {
    background-color: var(--frostedBackground) !important;
    border: none !important;
}

.rdw-dropdown-carettoopen {
    filter: invert();

}

.rdw-dropdown-optionwrapper {
    background-color: var(--base-color) !important;
    border: none !important;
}


.rdw-dropdown-highlighted {
    background-color: var(--frostedBackground) !important;
    border: none !important;
}

.rdw-dropdownoption-default:hover {
    background-color: var(--frostedBackground) !important;
    border: none !important;

}

.rdw-dropdownoption-active {
    background-color: var(--frostedBackground) !important;
    border: none !important;
}

.rdw-editor-toolbar * {
    box-shadow: none !important;

}

.rdw-colorpicker-modal {
    background-color: var(--base-color) !important;
    border: none !important;
}

.rdw-embedded-modal {
    background-color: var(--base-color) !important;
    border: none !important;
    border: var(--frostedBorder2) !important;
}

.rdw-emoji-modal {
    background-color: var(--base-color) !important;
    border: var(--frostedBorder2) !important;
}

.rdw-image-modal {
    background-color: var(--base-color) !important;
    border: var(--frostedBorder2) !important;

}

.rdw-image-modal * {
    color: white !important;
}

.rdw-image-modal * input {
    background-color: var(--frostedBackground) !important;
    border: none !important;

}

.rdw-image-modal-btn-section>button {
    background-color: var(--frostedBackground) !important;
    border: none !important;
    border: var(--frostedBorder2) !important;

}

.rdw-link-modal {
    height: 260px !important;
    background-color: var(--base-color) !important;
    border: var(--frostedBorder2) !important;

}

.rdw-link-modal-input {
    background-color: var(--frostedBackground) !important;
    border: none !important;


}

.rdw-link-modal-buttonsection>button {
    background-color: var(--frostedBackground) !important;
    border: none !important;
    border: var(--frostedBorder2) !important;


}

.half-text {
    display: flex;
    height: fit-content;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    justify-content: space-between;
    padding: 0px 60px;
}

.half-text-stack>h2 {
    font-size: 52px;
    text-transform: uppercase;

    line-height: 65px;
}

.half-text-stack>h2>span {
    font-weight: 700;
    color: var(--main-color);
}

.half-text-content {
    align-self: flex-start;
    position: sticky;
    top: 0px;
}

.half-text-stack {
    max-width: 600px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;


}

.half-text-stack>p {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
}

.token-table-item {
    display: flex;
}

.token-table-item {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(255, 255, 255, 0.132);
    padding-bottom: 13px;
}

.token-table-container>.token-table-item:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
}

.token-table-container>.token-table-item:first-of-type {
    border: none;
    padding-bottom: 0px;
}

.token-table-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    background-color: var(--frostedBackground);
    backdrop-filter: blur(29px);
    padding: 40px;
    border-radius: 20px;
    margin-top: 100px;
    outline: solid 1px rgba(255, 255, 255, 0.132);
}

.token-table-item>h4 {
    color: var(--text-gray);
    font-weight: 600;
    font-size: 14px;
}

.token-table-item>p {
    color: var(--text-gray);
    font-weight: 500;
    font-size: 14px;
}

.header-token>h4 {
    color: var(--main-color);
}

.header-token>p {
    color: var(--main-color);
}

.desc-chunk {
    width: 100%;
    background-color: var(--frostedBackgroundLarge);
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: 0.2s ease;
    flex-direction: column;
    outline: solid 1px rgba(255, 255, 255, 0.132);
}

.desc-chunk-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
}

.desc-chunk-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    ;
}

.desc-chunk-container>p {
    color: var(--text-gray);
    font-weight: 500;
    font-size: 13px;
    text-align: center;
}

.duo-desc-chunk {
    display: flex;
    gap: 20px;
}

.focused-chunk {
    border: solid 3.5px var(--main-color);
    box-shadow: 0px 0px 40px 3px rgba(18, 177, 255, 0.89);
    ;
}

.top-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px rgba(255, 255, 255, 0.132);
    width: 100%;
    padding: 9px;
}

.top-cell>span {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-gray);
}

.top-cell>p {
    font-weight: 600;
    font-size: 14px;
    color: #ffffffd9;
}



.mantine-ndinzw {
    border: var(--frostedBorder2);
}


.mobileText {
    display: none;
}

section>div>h3 {
    margin-top: 50px;
}

.menu-btn {
    background-color: transparent;
    outline: var(--frostedBorder2);
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
}

.mantine-temlmy {
    background-color: var(--base-color);
    border: var(--frostedBorder2);
    border-radius: 14px;
}

.mantine-Accordion-item {
    background-color: var(--frostedBackgroundSolid) !important;
}

.metamask-btn>span {
    font-size: 15px;
    font-weight: 600;
}

.mantine-eby8v3[data-hovered] {
    background-color: var(--frostedBackground);

}

.mobile-nav {
    display: none !important;
}

.accord-container {
    margin: auto;
    width: 100%;
    justify-content: center;
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1535px;
}

.mantine-Accordion-label {
    font-size: 18px;
    color: white !important;
}

.mantine-Accordion-root {
    width: 100%
}


[data-mantine-color-scheme=light] .m-4271d21b:not(:disabled, [data-disabled]):hover,
[data-mantine-color-scheme=light] .m-6939a5e9:not(:disabled, [data-disabled]):hover {
    --_control-background-color: var(--frostedBackground) !important;
}

.m-1f921b3b[data-active] {
    background-color: var(--frostedBackground) !important;

}

.m-4ba585b8 {
    padding: 25px 26px 25px 32px !important;
}

.m-1f921b3b:first-of-type,
.m-1f921b3b:first-of-type>[data-accordion-control] {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
}

.m-1f921b3b:last-of-type,
.m-1f921b3b:last-of-type>[data-accordion-control] {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
}


.rdw-image-alignment-options-popup {
    background-color: var(--base-color) !important;
    border: var(--frostedBorder2) !important;
}

.rdw-image-imagewrapper>img {
    width: 100% !important;
    object-fit: contain;
}

.rdw-link-decorator-wrapper>a * {
    color: var(--main-color) !important;
}

.rdw-link-decorator-wrapper>a:hover {
    text-decoration: underline !important;
    text-decoration-color: var(--main-color) !important;
    text-decoration-thickness: 2px;
    cursor: pointer;
}

.rdw-embedded-modal-link-input,
.rdw-embedded-modal-size-input,
.rdw-embedded-modal-btn {
    background-color: var(--frostedBackground) !important;
    border: none !important;
    color: white !important;
}

.rdw-embedded-modal {
    height: fit-content !important;
}

iframe {
    width: 100% !important;
    aspect-ratio: 16/9 !important;
}


[data-mantine-color-scheme=light] .m-4ba585b8 {
    background-color: transparent !important;
}

article img {
    width: 100% !important;
    margin: 20px 0px;
    border-radius: 12px;
}

article iframe {
    width: 100% !important;
    margin: 20px 0px;
    border-radius: 12px;
}

article ul {
    list-style-type: initial !important;
    margin-left: 20px;
}

article ol {
    margin-left: 20px;
}


.footer {
    width: 100%;
    display: flex;
    height: 380px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
    background-repeat: no-repeat;
}

.footer-inner>img {
    width: 150px;
    height: auto;
    object-fit: scale-down;
}

.footer-inner {
    display: flex;
    gap: 90px;
}

.footer-block {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.footer-block>a {
    display: flex;
    align-items: center;
    gap: 4px;
}

.footer-block>a>i {
    font-size: var(--px19);
    color: var(--text-gray);
}

.footer-block>h3 {
    font-weight: 600;
    font-size: var(--px16);
}

.footer-block>a {
    color: var(--text-gray);
    font-weight: 500;
    font-size: var(--px15);
    text-decoration: none;
    cursor: pointer;
}

.footer-block>a:hover {
    text-decoration: underline;
}

.footer-bottom {

    width: 100%;
    display: flex;
    justify-content: center;
}

.footer-bottom>p {
    font-size: var(--px14);
    color: var(--text-gray);
    font-weight: 500;
    margin-bottom: 10px;

}

.footer-block {
    display: flex;
    flex-direction: column;
}









.user-item-container {


    justify-content: center;
    height: 100vh
}

.user-items {
    padding: 0px 60px;
    width: 100%;
    margin: auto;
    max-width: 1535px;
}


.user-items>ol {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 40px;
}

.user-items>ol>li {
    padding: 37px 43px;
    background-color: var(--frostedBackgroundSolid);
    outline: var(--frostedBorder2);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.user-items>ol>li>h4 {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 20px;
}

.user-items>ol>li>p {
    font-size: var(--px16);
    font-weight: 500;
    line-height: 30px;
    max-width: 275px
}

.user-items>ol>li>h4>span {
    font-size: 25px;
    font-weight: 700;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    outline: solid 3.5px var(--main-color)
}

.user-items>ol>li>a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--main-color);
    font-weight: 600;
    font-size: var(--px16);
    text-decoration: none;
}

.notice-banner {
    width: 100%;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;

}

.notice-banner-text>button {
    background-color: var(--frostedBackgroundSolid);
    border: none;
    padding: 5px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
}

.notice-banner-text {
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 9px;

}

.notice-banner-text>p {
    font-size: var(--px16);
    font-weight: 600;
    color: whtie;
    margin-right: 10px;

}

.loading-view {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: var(--frostedBackgroundSolid);
    backdrop-filter: blur(29px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

}

.loading-inner {
    display: flex;
    gap: 20px;
    align-items: center;
}

.loading-inner>img {
    width: 60px
}



.loading-text>h1 {
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
}

.loading-text>p {
    font-size: 14px;
    margin-top: -2px;
    opacity: 0.7;
}

.hero-sectional {
    margin: 10vh 0px
}

.mantine-Modal-body {
    background-color: var(--frostedBackgroundSolid);
}

.m-1b7284a3 {
    background-color: var(--frostedBackgroundSolid) !important;
}

.mantine-Modal-header {
    background-color: var(--frostedBackgroundSolid) !important;


}

.content-wrapper>ul {
    list-style-type: disc;
    position: sticky;
    top: 120px;
    height: fit-content;

}

.content-wrapper>ul>li>ul {
    list-style-type: circle;
    margin-left: 15px
}

.content-wrapper {
    margin: auto;

    padding: 0px 60px;
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 30px;
    margin-top: 50px;
}

.content-feed {
    line-height: 35px;
    width: 890px;
}

.content-feed>ul {
    list-style-type: disc;
    padding: 0px;
    padding-left: 25px;
    color: var(--text-gray);
    font-size: 15px;

}

.content-feed>ul>li {
    color: var(--text-gray);
    font-size: 15px;
}

.content-feed>ol>li {
    color: var(--text-gray);
    font-size: 15px;
}

.content-feed>h1 {
    font-size: 50px;
    text-transform: uppercase;

}

.content-wrapper>ul>li>a {
    color: white;
    font-weight: 600;
}

.content-feed>ol {
    padding-left: 25px;
    color: var(--text-gray);
}

.content-feed>h3 {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 600;
    margin-top: 35px;
    scroll-margin-top: 117px;

}

.content-feed>h4 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
    margin-top: 17px;
    scroll-margin-top: 117px;
}

.content-feed>h4:focus {
    color: var(--main-color)
}

.content-feed-subtitle {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    opacity: 0.9;
}

.content-feed>table {
    margin-bottom: 50px;
    margin-top: 14px;
}

.content-feed>p {
    color: var(--text-gray);
    font-size: 15px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

}

td,
th {
    border: 1px solid #dddddd13;
    text-align: left;
    padding: 10px;
    font-size: 14px;
}


tr {
    background-color: var(--frostedBackgroundSolid);
}

tr:first-of-type {
    background-color: var(--frostedBackgroundDark);
    position: sticky;
    top: -1px;
}

.content-feed {
    width: 100%;
    max-width: 1000px;
}

.content-feed>* {
    width: 100%;
}

.seat-text {
    display: flex;

}

.seat-text>p {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.seat-text>p>span {
    font-weight: 700;
    color: var(--main-color);
}

.time-chip>i {
    font-size: 35px;
    color: var(--alt-color);
}

.time-chip {
    display: flex;
    margin-top: 15px;
    margin-bottom: -10px;

}

.time-chip>h3 {
    font-size: 25px;
    font-weight: 600;
    color: white;
    margin-left: 10px;
}

@media only screen and (max-width: 1400px) {
    .content-wrapper {
        display: flex;
        flex-direction: column;
        ;
    }

    .content-wrapper>ul {
        position: static !important;
        ;
    }

    .content-feed {
        width: 100%;
    }

    .content-feed>h1 {
        font-size: 35px;
    }

    .content-feed>* {
        width: 100%;
    }
}

@media only screen and (max-width: 1200px) {


    .user-items>ol>li>p {
        font-size: 13px;
    }

    .footer {
        height: 100%;
        background-image: none !important;
    }

    .user-items>ol {
        display: flex;
        flex-direction: column;
    }

    .user-items {
        padding: 40px 20px;
    }

    .user-item-container {
        height: fit-content;
    }

    .dash-wrapper {
        margin-top: 95px;
    }

    .on-button-group>div:last-of-type>a>button {
        outline: none !important;
    }

    .int-activity-container {
        display: flex !important;
        flex-direction: column;
        padding: 0px 23px !important;
    }

    .hero-center {
        position: static !important;
    }

    .nav-spacer {
        display: none !important;
    }

    .on-button-group {

        flex-direction: column;
        align-items: center;
    }

    .nav-left>img {
        width: 120px;
    }

    article>header>img {
        width: 100% !important;
    }

    .faucet-form {
        display: flex !important;
        flex-direction: column !important;
    }

    .scan-strip {
        display: none !important;
    }

    .mobile-nav {
        display: flex !important;
        width: 100%;
    }

    .article-container {
        grid-template-columns: 1fr !important;
    }

    .nav-bar {
        display: none !important;
    }

    .hero-center>h1 {
        font-size: 45px;
        line-height: 55px;
    }

    .hero-center>h1>span {
        font-size: 45px !important;
    }

    .home-ribbon {
        flex-direction: column;
        align-items: flex-start;
    }

    .home-ribbon-container {
        padding: 20px 27px;
    }

    .home-ribbon>.vertical-divider {
        display: none;
    }

    .home-text-wrapper>h1 {
        font-size: 45px;
        line-height: 55px;
    }

    .home-text-wrapper {
        padding: 20px 27px;
    }

    .flex-card-container {
        flex-direction: column;
        padding: 40px 20px
    }

    .scrolled-mobile {
        background-color: var(--base-color);
    }

    .flex-text {
        padding: 70px 46px;
    }

    .article-container {
        padding: 20px 27px !important;
    }

    .on-intro-text>h1 {
        font-size: 45px !important;
        line-height: 55px !important;
    }

    .onboard-cell {
        margin: 0px !important;
    }

    .on-intro {
        padding: 20px 27px !important;
    }


    .dash-label>h2 {
        padding: 0px 27px !important;
    }

    .login-desc {
        padding: 0px 27px !important;
    }

    .half-text {
        flex-direction: column;
    }

    .banner-notice {
        position: fixed;
        top: 0px;
    }

    .top-text {
        padding: 0px !important;
    }

    article {
        margin-top: 140px;
        padding: 25px;
    }

    .top-text>a {
        white-space: pre-wrap;
        position: fixed;
        bottom: 0px;
        left: 0px;
    }

    .news-headerImg>img {
        width: 100% !important;
    }

    article>section {
        padding: 0px;
    }

    .token-table-container {
        width: 100%;
    }

    .right-mobile-nav {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .back-btn {
        display: none;
    }

    .mainText {
        display: none;
    }

    .mobileText {
        display: block;
    }

    nav {
        padding: 25px 22px;
    }

    .hero {
        height: 100vh;
    }
}